<template>
    <div @click="hideAlert">
        <div v-if="!loader">
            <div>
                <b-card class="card card-congratulations" text-variant="center">
                    <b-avatar class="shadow mb-1" size="30" variant="primary">
                        <feather-icon icon="AwardIcon" size="18"/>
                    </b-avatar>
                    <h1 class="text-white">
                        {{ $t("welcome") }} {{ $store.state.auth.user.name }}
                    </h1>
                </b-card>
            </div>
            <b-card class="mt-2 pb-0 mb-0 alert alert-secondary" v-if="!showTasks && this.$store.state.auth.user.crole == 'seller' && level != 6">
                <b-row class="mb-0">
                    <b-col cols="12">
                        {{ $t(`level_${level}`) }}
                    </b-col>
                </b-row>
            </b-card>
            <div v-if="!showTasks && company && company.role == 'buyer' && buyer && buyer.identification_documentsBoD
               && isBuyerDocumentsIncomplete == true">
                <b-card class="pb-0 mb-0">
                    <feather-icon icon="AlertTriangleIcon" size="21"/>
                    {{ $t('you_still_have_docs_to_complete') }}
                    <b-button @click="$router.push('/buyer/upload-bod')" class="ml-1" size="sm">Complete</b-button>
                </b-card>
            </div>
            <br/>
            <b-row m-0 p-0 v-if="company && company.role">
                <b-col class="col-xl-12 col-lg-12 col-md-12 col-12">
                    <div class="d-md-flex justify-space-between col-xl-12 col-lg-12 col-md-12 col-12 mb-1">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-12" v-if="showTasks">
                            <h4 class="mb-0" style="color: #1B4332 ;">{{ $t("to_do") }}</h4>
                            <span>{{ $t("to_do_subtitle") }}</span>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-12" v-else>
                            <h4 class="mb-0 light-blue">{{ $t("your_statistics") }}</h4>
                            <span>{{ $t("statistics_subtitle") }}</span>
                        </div>
                        <div align="end" class="col-xl-6 col-lg-6 col-md-6 col-12 d-flex justify-content-end p-0" v-if=" !showTasks">
                            <b-button class="mr-1" to="/deposit" v-if="company.role == 'buyer' && this.$store.state.auth.user.access != false" variant="outline-dark">
                                <feather-icon icon="FileIcon" size="17"/>
                                {{ $t("deposit_funds") }}
                            </b-button>

                            <b-button @click="openWithdraw()" class="mr-1" color="primary" v-if="this.$store.state.auth.user.access != false">
                                <feather-icon icon="DollarSignIcon" size="16"/>
                                {{ $t("withdraw") }}
                            </b-button>

                            <b-button @click="openCurrencyExchange()" color="primary" v-if="this.$store.state.auth.user.access != false">
                                <feather-icon icon="DollarSignIcon" size="16"/>
                                {{ $t("currency_exchange") }}
                            </b-button>


                        </div>
                    </div>
                    <div v-if="showTasks">
                        <div v-if="company.role == 'seller'">
                            <CookpitTask
                                :data="company"
                                :index="index"
                                :key="index"
                                :prevTask="cookpitTasks[index - 1]"
                                :task="task"
                                v-for="(task, index) in cookpitTasks"
                            />
                        </div>
                        <div v-else>
                            <AddBuyer/>
                        </div>
                    </div>

                    <div v-else-if="!showTasks && (company.role == 'seller' || company.role == 'buyer')">
                        <div v-if="this.$store.state.auth.user.access != false">
                            <Balance :transactionTableType="transactionTableType"/>
                        </div>
                    </div>

                </b-col>
                <b-col class="col-xl-12 col-lg-12 col-md-12">
                    <FAQ :data="company"></FAQ>
                </b-col>
            </b-row>
        </div>
        <div v-else>
            <img src="/new-loader.svg"/>
        </div>
        <div>
            <WithdrawComponent
                :withdrawData="withdrawData"
                :withdrawModal="withdrawModal"
                :wthdrawLoader="wthdrawLoader"
                @cancelWithdraw="cancelWithdraw"
                @getBalance="getBalance"
                v-if="withdrawModal"/>
        </div>
        <b-modal
            scrollable
            size="md"
            style="padding-top: 20vh;"
            v-model="cannotWithdrawModal"
        >
            <template #modal-footer="{}" class="p-0 m-0">
                <p class="d-none">-</p>
            </template>
            <div>
                 <span class="d-flex alert alert-danger p-1">
                      <feather-icon class="mr-2 " icon="AlertTriangleIcon" size="21"/>
                      <h6 class="text-danger p-0 m-0 ">{{ $t("withdraw_warning") }}</h6>
                 </span>
            </div>
        </b-modal>


        <!-- twoFactor modal-->

        <b-modal hide-footer hide-header no-close-on-backdrop scrollable size="md" style="padding-top: 20vh;" v-model="twoFactor">
            <div>
                <two-factor @toggle-two-factor="toggleTwoFactor"></two-factor>
            </div>
        </b-modal>

        <!-- exchange modal-->

        <b-modal hide-footer hide-header scrollable size="md" style="padding-top: 20vh;" v-model="currencyExchangeModal">
            <div>
                <exchange @toggle-exchange-modal="toggleExchangeModal" :changeTransactionTableType="changeTransactionTableType" />
            </div>
        </b-modal>
        <!--<b-modal hide-footer hide-header no-close-on-backdrop scrollable size="md" style="padding-top: 20vh;" v-model="onlineForm">-->
        <!--<div class="p-2">-->
        <!--<h5>{{ $t('task_buyer_title') }}</h5>-->
        <!--</div>-->
        <!--<div class="d-flex justify-content-end">-->
        <!--<b-button @click="redirectOnlineForm" variant="secondary">Start filling out</b-button>-->
        <!--</div>-->
        <!--</b-modal>-->
    </div>
</template>

<script>
  import {ValidationObserver, ValidationProvider} from "vee-validate";
  import CookpitTask from "@/views/dashboard/CookpitTask";
  import Balance from "@/views/balance/Balance";
  import VueApexCharts from "vue-apexcharts";
  import AddBuyer from "@/components/buyer-cookpit/AddBuyer.vue";
  import FAQ from "./FAQ.vue";
  import WithdrawComponent from "@/components/common/WithdrawModal.vue";
  import TwoFactor from "../auth/TwoFactor";
  import Exchange from "../auth/Exchange";

  export default {
    components: {
      TwoFactor,
      CookpitTask,
      VueApexCharts,
      FAQ,
      Balance,
      ValidationProvider,
      ValidationObserver,
      AddBuyer,
      WithdrawComponent,
      Exchange
    },
    data() {
      return {
        // amount_fee: null,
        // amount_total: null,
        // currencies: null,
        // currency_name: null,
        // exchange_currency_name: null,
        // exchangeCurrencies: {
        //     currency: null,
        //     exchange_currency: null,
        //     amount: null,
        // },
        level: 0,
        balance: [],
        cookpitTasks: [],
        number_of_employees: "",
        loader: true,
        docs: "",
        company: null,
        showTasks: true,
        seller: 0,
        data: null,
        withdrawModal: false,
        currencyExchangeModal: false,
        wthdrawLoader: false,
        cannotWithdrawModal: false,
        buyer: null,
        withdrawData: {
          withdraw_amount: null,
          iban: null,
          bank_name: null,
          bic: null,
          reference_number: null,
          account_owner: null,
          balance: null,
          accountOwnersAddress: null,
          purposePayment: null,
          pdfPath: null
        },
        twoFactor: false,
        onlineForm: false,
        transactionTableType: null
      };
    },
    computed: {
      isBuyerDocumentsIncomplete() {
        const {company, buyer} = this;
        if (company && company.role === 'buyer' && buyer) {
          const identificationDocs = buyer.identification_documentsBoD;
          return (
              identificationDocs.passport_front.includes('null') ||
              identificationDocs.passport_back.includes('null') ||
              !identificationDocs.certificate_of_incorporation ||
              !identificationDocs.articles_of_association ||
              !identificationDocs.extract_company_registry ||
              !identificationDocs.proof_of_business ||
              !identificationDocs.shareholder_registry
          );
        }
      }
    },
    mounted() {
      // this.toggleLanguage(this.$store.state.app.company.value.language);

    },
    created() {
      // this.getCurrencies();
      this.syncSumsub();
      this.getTasks();
      if (this.$store.state.auth.user.company_id) {
        this.getStatistics();
      }
      this.getBalance(1);
      this.getTwoFactor();
      if (this.$store.state.auth.user.crole == 'buyer') {
        this.get_online_form()
      }

    },
    methods: {

      changeTransactionTableType(newValue) {
        this.transactionTableType = newValue
      },

      redirectOnlineForm() {
        this.$router.push("/cookpit/task/fill-online-form");
      },
      get_online_form() {
        this.$http
            .post("/online_form")
            .then((res) => {
              if (res.data && res.data.value && !res.data.value.id) {
                if (Object.keys(res.data.value).length === 0 && this.$store.state.auth.user.company.status == 1) {
                  this.onlineForm = true
                }
              }
            })
      },


      getTwoFactor() {
        this.$http
            .post(`/company/show/${this.$store.state.auth.user.company_id}`)
            .then((res) => {
              if (res && res.data.value.main_contact) {
                this.twoFactor = res.data.value.main_contact.is_two_factor == 1 ? true : false
              }
            })
      },

      toggleTwoFactor() {
        this.twoFactor = !this.twoFactor;
      },

      toggleExchangeModal() {
        this.currencyExchangeModal = !this.currencyExchangeModal;
      },

      syncSumsub() {
        const user = this.$store.state.auth.user;
        if (!user.status || !(user.company && user.company.status)) {
          this.$http.post(`/user/sumsub/sync/${user.id}`).then(({data}) => {
            if (data) {
              if (data.value.is_active == 1) {
                window.location.reload();
              } else {
                console.log(data)
              }
            }
          });
        }
      },
      toggleLanguage(language) {
        this.$i18n.locale = language;
        this.$store.commit("app/UPDATE_LANGUAGE", language);
        this.$http.post("/company/update/language", {language: language});
      },

      openWithdraw(data) {

        this.$http.post(`/currency/withdraw-currency/${this.company.id}`).then((response) => {
          if (response.data.status == 200) {
            if (response.data.value == null) {
              this.cannotWithdrawModal = true;
            } else {
              this.withdrawModal = true;
            }
          }
        });


      },

      openCurrencyExchange() {
        this.currencyExchangeModal = true;
      },

      cancelWithdraw() {
        this.withdrawModal = false;
      },

      showSuccessMessage(message, type) {
        let text = this.$t(message);
        this.$swal({
          position: "center",
          icon: "success",
          title: text,
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });

      },
      hideAlert() {
        this.$store.commit("auth/SHOW_ALERT", false);
      },
      getTasks() {
        this.$http.post("/company/tasks").then((res) => {
          this.loader = true;
          this.cookpitTasks = res.data.value;
          let user = this.$store.state.auth.user;
          if (user.crole != "seller") {
            this.seller = 1;
          } else {
            this.seller = 0;
          }
          this.cookpitTasks.forEach((el) => {
            if (
                el.status == 1 &&
                el.title != "Complete identification" &&
                el.visible == 1
            ) {
              this.showTasks = false;
            }
          });
          this.loader = false;
        });
      },
      getStatistics() {
        this.$http
            .post(`/company/show/${this.$store.state.auth.user.company_id}`)
            .then((res) => {
              this.loader = true;
              this.company = res.data.value;
              if (res.data.value.role == 'buyer') {
                this.getBuyer();
              }
              if (res && res.data.value.financial_data) {
                this.number_of_employees =
                    res.data.value.financial_data.number_of_employees;
              }
              if (res && res.data.value.main_contact) {
                this.withdrawData.reference_number = res.data.value.main_contact.id;
                this.withdrawData.account_owner = res.data.value.main_contact.name + ' ' + res.data.value.main_contact.last_name;
              }
              this.withdrawData.iban = res.data.value.iban ? res.data.value.iban : null;
              this.withdrawData.bank_name = res.data.value.bank_name ? res.data.value.bank_name : null;
              this.withdrawData.bic = res.data.value.bic ? res.data.value.bic : null;
              this.loader = false;
              if (res.data.value && res.data.value.level) {
                this.level = res.data.value.level;
              }

            });
      },
      getBuyer() {
        this.$http
            .post(`/buyer/show`)
            .then((res) => {
              if (res) {
                this.buyer = res.data.value;
              }
            });
      },
      getBalance(id) {
        this.loader = true;
        this.$http.post("/transactions/getBalance", {
          "currency_id": id
        }).then((res) => {
          if (typeof res.data.value != "undefined" && res.data.value) {
            this.balance = res.data.value;
            this.withdrawData.balance = res.data.value;
          }
          this.loader = false;
        });
      },
    },
  };
</script>

<style>
    .bounce-leave-active {
        animation: bounce-in 0.6s reverse;
    }

    @keyframes bounce-in {
        0% {
            transform: scale(0);
        }
        50% {
            transform: scale(1.25);
        }
        100% {
            transform: scale(1);
        }
    }
</style>
